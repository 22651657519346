import { getSignatures } from "../commonDynmicForms/signature";
import { FillableBy } from "./form_enums";

export default {
    fillable_by:FillableBy.Evaluator,
    title:
        "KPS Cardiohelp Competency Checklist",
    sections: [
        {
            title: "Identify the following components of the circuit:",
            type: "fields",
            fields: [
                {
                  fillable_by : FillableBy.Evaluator,
                    type: "a-checkbox-group",
                    span: 24,
                    value: [],
                    options: [
                        { text: "Power button", span: 24 },
                        { text: "Emergency override button", span: 24 },
                        { text: "Safety button", span: 24 },
                        { text: "Unlock button", span: 24 },
                        { text: "Connections for external pressure sensors:", span: 24 },
                        { text: "Venous pressure", span: 24, offset: 1 },
                        { text: "Internal pressure", span: 24, offset: 1},
                        { text: "Arterial pressure", span: 24, offset: 1 },
                        { text: "Connection for sensors of disposable HLS set", span: 24 },
                        { text: "Connection for flow/bubble sensor", span: 24 },
                        { text: "Connection for venous probe sensor", span: 24 },
                    ],
                },
            ]
        },
        {
            title: "Demonstrate the following skills:",
            type: "fields",
            fields: [
                {
                  fillable_by : FillableBy.Evaluator,
                    type: "a-checkbox-group",
                    span: 24,
                    value: [],
                    options: [
                        { text: "Turn the Cardiohelp console on", span: 24 },
                        { text: "Zero the flow probe", span: 24 },
                        { text: "Activate Global Override mode", span: 24 },
                        { text: "Identify when console is in Global Override mode", span: 24 },
                        { text: "Deactivate Global Override mode", span: 24 },
                        { text: "Zero the pressure sensors (explain at what point should pressures be zeroed)", span: 24},
                        { text: "Set RPMs to 3000 using the knob", span: 24},
                        { text: "Set RPMs to 3300 using touchscreen", span: 24},
                        { text: "Lock the screen", span: 24 },
                        { text: "Unlock the screen", span: 24 },
                        { text: "Set the flow low alarm limit to 1.5 LPM", span: 24 },
                        { text: "Start data recording at 5 minute interval", span: 24 },
                        { text: "Demonstrate how to verify system is recording data on the home screen", span: 24 },
                        { text: "Activate timer 1", span: 24 },
                        { text: "Set countdown timer to 15 minutes and activate it", span: 24 },
                        { text: "Set the intervention of the arterial pressure limit to 140mmHg", span: 24 },
                        { text: "Increate RPMs to 1500, slowly clamp arterial line, and explain what is happening", span: 24},
                        { text: "Identify how one can determine an intervention is set and active, then turn off the intervention for the arterial pressure limit", span: 24},
                        { text: "Identify the current pressure drop and set an alarm for double the current value", span: 24},
                        { text: "Set the flow to 2.0 LPM in LPM mode, then slowly clamp the arterial line and explain what happens", span: 24 },
                        { text: "Confirm the arterial bubble intervention is not activated", span: 24 },
                        { text: "Open the arterial bubble sensor and explain what happens", span: 24 },
                        { text: "Activate arterial bubble monitoring intervention", span: 24 },
                        { text: "Explain why the arterial bubble alarm may be activated and what would happen to the console/circuit if it was activated while an intervention was set", span: 24 },
                        { text: "Open the arterial bubble sensor", span: 24 },
                        { text: "Reattach arterial bubble sensor and reset the bubble alarm", span: 24 },
                        { text: "Explain zero flow mode", span: 24 },
                        { text: "Activate zero flow mode", span: 24},
                        { text: "Why may zero flow mode occur? Is zero flow mode the same as zero RPMs?", span: 24},
                        { text: "Explain possible reason and trouble shooting for “Pump Disposable Error”", span: 24},
                        { text: "Deactivate zero flow mode", span: 24 },
                        { text: "State the battery life of Cardiohelp and where and when it should be plugged in", span: 24 },
                        { text: "Place the venous probe on the blood phantom", span: 24 },
                        { text: "Recalibrate the blood parameters", span: 24 },
                        { text: "Save the current parameters", span: 24 , offset: 1 },
                        { text: "Update the blood draw with the following labs:  SVO2 86, Hgb 11.0, HCT 26", span: 24 , offset: 1},
                        { text: "End data recording and explain how to export the data to a USB stick", span: 24 },
                        { text: "Activate Emergency Mode", span: 24 },
                        { text: "Describe how to turn off emergency mode", span: 24 ,  offset: 1},
                        { text: "Describe what features/aspects of Cardiohelp fully function in emergency mode", span: 24 ,  offset: 1},
                        { text: "Demonstrate how to determine current battery life", span: 24},
                        { text: "Demonstrate how to determine if console is connected to AC power/charging/running on battery", span: 24 },
                        { text: "Demonstrate how to raise and lower the safety bar", span: 24 },
                        { text: "Hand crank at 4000 rpms", span: 24 },
                        { text: "Demonstrate how to return HLS disposable from the hand crank to a console", span: 24},
                        { text: "I have reviewed all of the Keystone Cardiohelp clinical reference materials and am comfortable with all content.", span: 24},
                    ],
                },
            ]
        },
        {
            title: "Signatures",
            type: "signature",
            fields: getSignatures(FillableBy.Evaluator)
          },
    ]
}